/* Example custom styles for slick carousel arrow buttons */
.slick-prev {
    left: 20px;
    z-index: 10;
    
  }
  
  .slick-next {
    right: 20px;
    z-index: 10;
   
  }
  